<template>
  <div class="calendar">
    <div class="main-body">
      <!-- 操作栏 -->
      <div class="body-top">
        <div class="year">{{ nowYear }}年</div>
        <div class="month">{{ nowMonth + 1 }}月</div>
        <div class="changeMonth">
          <div class="imageFlex" @click.stop="changeMonth('left')">
            <img :src="require('./img/arrow-l.png')" />
          </div>

          <div>{{ nowMonth + 1 }}</div>
          <div class="imageFlex" @click.stop="changeMonth('right')">
            <img :src="require('./img/arrow-r.png')" />
          </div>
        </div>
      </div>
      <!-- 日历主体 -->
      <div class="body-bottom">
        <div class="headLine">
          <div
            class="weekStrItem"
            v-for="(item, index) in headList"
            :key="`${item}${index}`"
          >
            {{ item }}
          </div>
        </div>
        <div class="calendar">
          <!-- 前一个月数据展示 -->
          <div
            v-for="(item, index) in previousMonthList"
            :key="`${item}${index}`"
            class="emptyBox"
          >
            {{ item }}
          </div>
          <div
            class="calendar-item"
            v-for="(item, index) in dayList"
            :key="`${item}${index}`"
            @click.stop="changeDay(item)"
          >
            <div
              :class="[
                'block',
                { active: item.active },
                {
                  haveSessions:
                    haveSessionsList.join(',').indexOf(item.date) != -1 &&
                    !item.active,
                },
              ]"
            >
              {{ item.day }}
              <div
                class="point"
                v-if="
                  haveSessionsList.join(',').indexOf(item.date) != -1 &&
                  !item.active
                "
              ></div>
            </div>
          </div>
          <!-- 后一个月数据展示 -->
          <div
            v-for="(item, index) in nextMonthList"
            :key="`${item}${index}`"
            class="emptyBox"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nowYear: "",
      nowMonth: "",
      headList: ["一", "二", "三", "四", "五", "六", "日"],
      dayList: [],
      nowDate: "",
      date1: "",
      emptyNum: 0,
      firstDay: "",
      nextMonthList: [], //后一个月
      previousMonthList: [], //前一个月
    };
  },
  props: {
    // 哪些天有场次
    haveSessionsList: {
      type: Array,
      default: () => [],
    },
    // 是否可以选择之前的日期
    canChangeMonth: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // this.getNow();
  },
  methods: {
    // 切换月份
    changeMonth(type) {
      if (type == "left") {
        //前
        if (!this.canChangeMonth && this.firstDay == this.date1) {
          return;
        }
        if (this.nowMonth != 0) {
          this.nowMonth--;
          this.getDaysArr(new Date(this.nowYear, this.nowMonth).getTime());
        } else {
          this.nowYear--;
          this.nowMonth = 11;
          this.getDaysArr(new Date(this.nowYear, this.nowMonth).getTime());
        }
      } else {
        // 后
        if (this.nowMonth != 11) {
          this.nowMonth++;
          this.getDaysArr(new Date(this.nowYear, this.nowMonth).getTime());
        } else {
          this.nowYear++;
          this.nowMonth = 0;
          this.getDaysArr(new Date(this.nowYear, this.nowMonth).getTime());
        }
      }
      this.dayList.forEach((ele) => {
        if (ele.date == this.nowDate) {
          ele.active = true;
        }
      });
      this.getFirstDay();
      this.$emit("changeMonth", this.firstDay);
    },
    // 选择某一天
    changeDay(item) {
      if (item.active) {
        return;
      } else {
        this.dayList.forEach((ele) => {
          ele.active = false;
        });
        item.active = true;
      }
      this.$emit("dateClick", item);
    },
    // 获取当前时间
    getNow(time) {
      let date = new Date(time);
      const y = date.getFullYear();
      const m =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      const d = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      this.nowDate = `${y}-${m}-${d}`;
      this.date1 = `${y}-${m}-01`;
      this.nowYear = date.getFullYear();
      this.nowMonth = date.getMonth();
      this.getDaysArr(new Date(this.nowYear, this.nowMonth).getTime());
      this.getFirstDay();
      this.dayList.forEach((ele) => {
        if (ele.date == this.nowDate) {
          ele.active = true;
          this.$emit("dateClick", ele);
        }
      });
    },
    // 获取每个月第一天/正确展示对应星期/获取前一个月和后一个月所需数组
    getFirstDay() {
      this.nextMonthList = [];
      this.previousMonthList = [];
      let obj = this.dayList[0];
      this.firstDay = obj.date;
      // console.log(this.firstDay);
      // console.log(
      //   new Date(obj.year, obj.month - 1, obj.day).getDay(),
      //   `周${
      //     this.headList[new Date(obj.year, obj.month - 1, obj.day).getDay()]
      //   }`
      // );
      // if()
      this.emptyNum = new Date(obj.year, obj.month - 1, obj.day).getDay() - 1;
      if (this.emptyNum == -1) {
        this.emptyNum = 6;
      }
      let days1 = new Date(obj.year, obj.month - 1, 0).getDate();
      for (let i = days1 - this.emptyNum + 1; i <= days1; i++) {
        this.previousMonthList.push(i);
      }
      let days2 = 42 - this.dayList.length - this.previousMonthList.length;
      for (let i = 1; i <= days2; i++) {
        this.nextMonthList.push(i);
      }
      // console.log(this.previousMonthList, "前一个月的天数");
      // console.log(this.nextMonthList, "后一个月的天数");
      // console.log(days1);
      // console.log(obj.year, obj.month);
    },
    // 获取指定月份天数
    getDaysArr(timestamp) {
      const d = new Date(timestamp);
      const y = d.getFullYear();
      const m = d.getMonth() + 1;
      const week = d.getDay();
      const days = new Date(y, m, 0).getDate();
      this.dayList = [];
      for (let i = 1; i <= days; i++) {
        const day_str = i >= 10 ? i : "0" + i;
        this.dayList.push({
          date: `${y}-${m >= 10 ? m : "0" + m}-${day_str}`,
          day: `${i}`,
          month: `${m}`,
          year: `${y}`,
          active: false,
          week: week,
          haveSessions: false,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.calendar {
  box-sizing: border-box;
}
.main-body {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(225, 225, 225, 0.49);
  border-radius: 16px;
  .body-bottom {
    padding: 20px 10px 20px;
    box-sizing: border-box;
    .calendar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .emptyBox {
        width: calc(100% / 7);
        text-align: center;
        // min-height: 100px;
        // line-height: 100px;
        color: #cfcfcf;
      }
      .calendar-item {
        width: calc(100% / 7);
        text-align: center;
        position: relative;
        min-height: 50px;
        line-height: 50px;
        .text {
          z-index: 1000;
        }
        .block {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        .point {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 8px;
          height: 8px;
          background: #1874fd;
          border-radius: 50%;
        }
        .active {
          color: #ffffff;
          background: #1874fd;
          border-radius: 12px;
        }
        .haveSessions {
          color: #1874fd;
        }
      }
    }
    .headLine {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      color: #cfcfcf;
      .weekStrItem {
        height: 30px;
        // line-height: 40px;
        width: calc(100% / 7);
        text-align: center;
      }
    }
  }
  .body-top {
    height: auto;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px 30px 20px 30px;
    .changeMonth {
      display: flex;
      align-items: center;
      width: 110px;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      .imageFlex {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 14px;
          height: 22px;
        }
      }
    }
    .month {
      flex: 1;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      padding-left: 25px;
      box-sizing: border-box;
    }
    .year,
    .month {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
